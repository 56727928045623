<template>
  <div class="layout_bottom">
    <div class="bottom_one">
      <div class="">
        <div class="inner">
          <div class="left_center">
            <div class="center_1">
              <img src="@/assets/images/loanImg/logo_icon_2.png" alt="">
              <h2 class="title">CréditoPlus</h2>
            </div>
          </div>
          <div class="right_center">
            <a href="https://credito-plus.com/#/privacypolicy">Política de Privacidad</a>
            <a href="https://credito-plus.com/#/servicesagreement">Términos de Servicio</a>
            <!-- <div>
              <a href="https://weprestamos.com/#/terms_of_service">Terms & Conditions</a>
              <a href="https://weprestamos.com/#/privacy_policy">Privacy Policy</a>
            </div> -->
            <!-- <div>
              <p>Service Time:</p>
              <p>Monday to Sunday 9:00-18:30</p>
            </div> -->
            <div>
              <a>Copyright © 2023 CréditoPlus</a>
            </div>
          </div>
          <!-- <div class="item_download" @click="downloadApp()">
            <img src="@/assets/images/loanImg/Google-play.png" alt="">
          </div> -->
        </div>
        <!-- <div class="inner2">
          <p>GRAYSVILLE TRADING LIMITED</p>
        </div> -->
      </div>
    </div>
    <!-- <div class="bottom_two">
      Copyright © CréditoPlus All rights reserved
    </div> -->
  </div>
</template>
<script>
export default {
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=credito.plus.dinero')
    }
  }
}
</script>
<style lang="scss" scoped>
.layout_bottom{
  min-width: 1200px;
  font-family: Arial, Arial;
  .bottom_one{
    background: #060300;
    color: #fff;
    padding: 90px 0 70px 0;
    .inner{
      width: 100%;
      padding:0px calc((100% - 1150px) / 2) 0;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      a{
        color: white;
        line-height: 50px;
      }
    }
    .inner2{
      width: 100%;
      padding:0px calc((100% - 1150px) / 2) 0;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      p{
        margin: 10px 0;
      }
      a{
        color: white;
      }
    }
    .left_center{
      float: left;
      width: 30%;
      display: flex;
      align-items: center;
      .center_1{
        display: flex;
        img{
          width: 50px;
          height: 50px;
        }
        .title{
          display: flex;
          align-items: center;
          margin-left: 20px;
          width: 250px;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .right_center{
      display: flex;
      overflow: hidden;
      width: 70%;
      justify-content: space-between;
      div{
        display: flex;
        flex-direction: column;
        margin-right: 10px;
      }
      p{
        font-size: 12px;
        line-height: 50px;
      }
      h4{
        margin-bottom: 15px;
        font-weight: 100;
      }
    }
    .item_download{
      display: flex;
      width: 200px;
      // background: black;
      color: white;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      margin-left: 50px;
      img{
        width: 200px;
        height: 60px;
        margin-right: 5px;
      }
    }
  }
  .bottom_two{
    background: #4D4D4D;
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 80px;
  }
}
@media only screen and (max-width: 520px){
  .layout_bottom{
    min-width: calc(100vw) !important;
    width: calc(100vw) !important;
  }
  .bottom_one{
    padding: 20px  !important;
    .right_center{
      width: 100% !important;
      flex-direction: column;
      margin: 15px 0;
    }
  }
  .bottom_two{
    font-size: 14px !important;
    line-height: 40px !important;
  }
  .inner{
    flex-direction: column;
    .item_download{
      margin-left: 0 !important;
    }
  }
}
</style>
