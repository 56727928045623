<template>
  <div class="loan_item">
    <div class="inner inner1">
      <div class="content flex jc-r ai-c">
        <div class="left_cell">
          <h2>Sobre Nosotros</h2>
          <p class="inner1_text">Bienvenido a CréditoPlus - Tu Solución <br> Financiera Instantánea!</p>
        </div>
        <!-- <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
        </div> -->
      </div>
    </div>
    <div class="inner inner2">
      <!-- <h1>Acerca de Nosotros</h1> -->
      <div>
        <h3>Nuestra Misión</h3>
        <p>En CréditoPlus, nos dedicamos a facilitar tu vida financiera proporcionándote soluciones de préstamos en efectivo rápidas, accesibles y seguras. Nuestra misión es ser tu aliado en el camino hacia la estabilidad financiera.
        </p>

        <h3>¿Por Qué Elegir CréditoPlus?</h3>
        <h3>Compromiso con la Rapidez</h3>
        <p>Entendemos la importancia del tiempo. En CréditoPlus, te ofrecemos préstamos rápidos para que obtengas el efectivo que necesitas en minutos.
        </p>

        <h3>Proceso Sencillo</h3>
        <p>Queremos hacer tu experiencia lo más fácil posible. Nuestro proceso de solicitud en línea es simple y directo, sin complicaciones ni papeleo innecesario.</p>

        <h3>Transparencia y Claridad</h3>
        <p>En CréditoPlus, creemos en la transparencia total. Todos los términos y condiciones de nuestros préstamos se presentan de manera clara, para que tomes decisiones informadas.</p>

        <h3>Seguridad Garantizada</h3>
        <p>La seguridad de tus datos y transacciones es nuestra máxima prioridad. Utilizamos tecnologías avanzadas para asegurar la privacidad y proteger tu información financiera.</p>

        <h3>Más que Préstamos</h3>
        <p>No somos solo un servicio de préstamos; somos tu socio financiero. Estamos comprometidos con tu éxito y bienestar financiero a largo plazo.</p>

        <h3>Equipo de Profesionales</h3>
        <p>Detrás de CréditoPlus hay un equipo de profesionales dedicados. Nuestro equipo de atención al cliente está aquí para ayudarte en cada paso del camino.</p>

        <p>Confiamos en que tu experiencia con CréditoPlus sea positiva y te ayude a alcanzar tus metas financieras. ¡Gracias por confiar en nosotros como tu compañero financiero de confianza!</p>
      </div>
    </div>
    <div class="inner inner3">
      <h1>Conecta con Nosotros</h1>
      <h2>¿Tienes preguntas o necesitas asistencia? Nuestro equipo de atención al cliente está aquí para ayudarte.
        Contáctanos y experimenta el servicio personalizado que nos distingue.</h2>
      <!-- <div class="content flex jc-sb">
        background: 'url(' + item.img + ')',backgroundSize:'100% 100%',
        <div v-for="(item,index) in inner_3items" :key="index" :style="{ width:'32%'}">
          <div class="item flex ai-c fd-c jc-c">
            <img :src="item.img2">
            <div>
              <p>{{ item.txt }}</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="inner inner6">
      <div class="inner6_item ai-c jc-c">
        <div class="inner6_item_left">
          <div>
            <!-- <h1 class="inner6_item_left_title">How To Contact Us</h1> -->
            <div v-for="(item,index) in inner6ItemLeftList" :key="index" class="inner6_item_left_list ">
              <img :src="item.img">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div class="inner6_item_right"><img src="@/assets/images/kefu.png" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_3items: [
        { title: 'Address:', txt: 'Correo electrónico de contacto: \n informacion@weprestamos.com', img: require('@/assets/images/loanImg/innner3_bg2.png'), img2: require('@/assets/images/loanImg/email.png') },
        { title: 'Email:', txt: 'Dirección: \n AV. Ejército Nacional Mexicano 754, \n Polanco, Granada, Miguel Hidalgo, \n 11520 Ciudad de México, CDMX, México', img: require('@/assets/images/loanImg/innner3_bg1.png'), img2: require('@/assets/images/loanImg/loa.png') },
        { title: 'Phone：', txt: 'Horario de atención: 9:00 am a 6:30 pm', img: require('@/assets/images/loanImg/innner3_bg3.png'), img2: require('@/assets/images/loanImg/time.png') }
      ],
      inner6ItemLeftList: [
        { title: 'Correo electrónico de atención al cliente: informes@credito-plus.com', img: require('@/assets/images/email.png') },
        { title: 'Dirección: Avenida Mazatlán 22, Colonia Condesa, Cuauhtémoc, 06140 Ciudad de México, CDMX', img: require('@/assets/images/address.png') },
        { title: 'Horario de Trabajo: Lunes a Domingo 9:00 am a 6:00 pm', img: require('@/assets/images/time.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=credito.plus.dinero')
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    width:100vw;
    // padding:50px calc((100% - 1100px) / 2) 50px;
    margin: 0 auto;
    max-width: 1500px;
    padding: 0 12.5rem;
    font-family: Arial, Arial;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 20px;
      }
      .inner1_text{
        color: #333;
        font-size: 20px;
        line-height: 30px;
        margin: 30px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    background: url("~@/assets/images/loanImg/inner1_2.png") no-repeat;
    height: 500px;
    background-size: 100% auto;
    h2{
      font-size: 40px;
    }
    .content{
      height: 100%;
    }
    .left_cell{
      width: 50%;
      background: #FFFFFF;
      border-radius: 15px;
      text-align: center;
      padding: 50px;
      p{
        font-size: 16px;
      }
    }
  }
  .inner2{
    // height: 420px;
    // background:#EEEEEE;
    padding-bottom: 50px;
    h1{
      font-size: 35px;
      text-align: center;
      margin:80px 0 30px;
    }
    div{
      padding: 50px;
      h3{
        margin-bottom: 10px;
        line-height: 30px;
      }
      p{
        font-size: 17px;
        line-height: 28px;
        margin-bottom: 30px;
      }
    }
  }
  .inner3{
    padding: 50px 0 ;
    background: #F5F5F5;
    h1{
      font-size: 40px;
      margin:60px 0 30px;
      text-align: center;
    }
    h2{
      padding: 0 240px;
      font-family: Arial, Arial;
      font-weight: normal;
      line-height: 30px;
      color: #666666;
    }
    h3{
      margin-bottom: 18px;
    }
    .content{
      margin-top: 80px;
      .item{
        background: #FFFFFF;
        border-radius: 15px;
        opacity:0.9;
        // margin: 180px 15px 20px 15px;
        padding: 0px 35px;
        height: 170px;
        img{
          width: 35px;
          margin-bottom: 15px;
        }
        p{
          line-height: 25px;
        }
      }
    }
  }
  .inner6{
    background: #F5F5F5;
    padding-bottom: 100px;
    .inner6_item{
      display: flex;
      .inner6_item_left{
        z-index: 2;
        border-radius: 10px;
        background: #5808A5;
        color: #ffffff;
        padding:35px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        width: 49%;
        h1{
          font-size: 35px;
          margin-bottom: 26px;
          width: auto;
          text-align: center;
        }
        .inner6_item_left_list{
          display: flex;
          align-items: center;
          margin: 15px 0;
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
          img{
            width: 24px;
            height: 24px;
            margin-right: 18px;
          }
        }
      }
        .inner6_item_right{
          width: 50%;
          z-index: 1;
          margin-left: -40px;
          img{
            padding: 2px;
            // border: 2px dashed rgb(206, 206, 206);
            width: 100%;
          }
        }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    // padding: 30px 0;
    background: white !important;
    color: #333;
    .inner{
      width: 100vw;
      min-width: calc(100vw) !important;
      padding: 0;
    }
    .inner1{
      height: 150px !important;
    }
    .inner2{
      width: calc(100vw);
      padding-bottom: 0;
      h1{
        line-height: 40px;
        margin: 0 0 30px;
        font-size: 25px;
      }
      div{
        padding: 0 20px;
        p{
          font-size: 12px;
        }
      }
    }
    .inner3{
      padding: 20px 0 0;
      h1{
        margin: 20px 0;
      }
      h2{
        font-size: 14px;
        padding: 0 20px;
      }
      .content{
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-top: 50px;
        div{
          width: 90% !important;
          margin-bottom: 20px;
        }
        .item{
          // margin: 150px 15px 15px 15px;
          margin: 0px auto;
          height: 280px;
          padding: 0;
        }
      }
    }

    .inner6{
      padding: 30px 20px 30px;
      h3{
        text-align: center;
        margin-bottom: 30px;
      }
      .inner6_item{
        width: 100%;
        flex-direction: column;
        .inner6_item_left{
          width: calc(100% - 40px) !important;
          padding: 20px 0;
          div{
            padding: 0 30px;
          }
          .inner6_item_left_title{
            font-size: 20px !important;
            text-align: center;
          }
          .inner6_item_left_list{
            justify-content: left;
            img{
              width: 17px;
              height: 17px;
            }
            span{
              font-size: 12px;
            }
          }
        }
        .inner6_item_right{
          width: calc(100% - 80px) !important;
          margin: -20px auto 0;
          z-index: 2;
        }
      }
    }
  }
}
</style>
